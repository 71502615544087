.front {
  text-align: center;
}

.backButton {
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  font-family: "Andale Mono", serif;
}

.backButtonText {
  color: white;
  font-weight: bold;
}

.menuElement {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 40px;
  font-family: "Andale Mono", serif;
  text-decoration: none;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menuElement:hover {
  font-weight: bold;
  background-color: lightgray;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  background-color: white;
  top: 0;
}

.headerContainer {
  position: sticky;
  top: 0;
}

.headerContent {
  height: 70px;
  width: auto;
  background-color: #1199dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.burgerMenu {
  padding-right: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.burgerLine {
  height: 2px;
  margin-bottom: 5px;
  background-color: white;
  width: 25px;
}

.underPage {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 200px;
  flex-direction: row;
  font-size: calc(10px + 1vmin);
  color: black;
  font-family: "Andale Mono", serif;
  text-align: left;
  margin-bottom: 100px;
}

button {
  background-color: #1199dd;
  padding: 12px;
  color: white;
  font-family: "Andale Mono", serif;
  font-weight: bold;
  border: none;
}

button:hover {
  cursor: pointer;
}

.hyttebokBox {
  align-self: flex-start;
  text-align: center;
  max-width: 400px;
  background-color: #cff4d9;
  border-radius: 12px;
  padding: 20px;
  margin-left: 20px;
}

@media (max-width: 50em) {
  .underPage {
    padding: 50px 20px;
    flex-direction: column-reverse;
  }

  .hyttebokBox {
    align-self: center;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.page,
.frontResidencen {
  background-color: #1199dd;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: white;
  font-family: "Andale Mono", serif;
}

.frontDark {
  background-color: #8cc9da;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Andale Mono", serif;
}

.frontLight {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  font-family: "Andale Mono", serif;
}

.checkbox {
  -webkit-appearance: none;
  background-color: white;
  border: 2px solid #1199dd;
  border-radius: 0;
  height: 22px;
  width: 22px;
  vertical-align: middle;
  margin-right: 12px;
}

.checkbox:checked {
  background-color: #1199dd;
}

.checkbox:checked:after {
  content: "✔";
  color: white;
  display: flex;
  justify-content: center;
}

.checkmarkLabel {
  display: block;
  margin-bottom: 22px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.url {
  text-decoration: underline;
}

.url:hover {
  font-weight: 600;
  color: darkgray;
}

.nameAndMenu {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: white;
  align-items: center;
}

.lenkeknapp:hover {
  font-weight: 600;
  text-decoration: underline;
}
