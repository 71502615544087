.layout {
  display: flex;
  flex-direction: column;
}

.anbefaling {
  margin-top: 4rem;
  border: 2px solid lightblue;
  padding: 1rem;
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  align-items: center;
  font-family: "Andale Mono", serif;
}

.modal select {
  margin-top: 6px;
  padding: 8px;
  width: 300px;
  font-family: "Andale Mono", serif;
}

.inputPar {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  width: 300px;
}

.modal input {
  margin-top: 6px;
  padding: 8px;
  font-family: "Andale Mono", serif;
}

.modal textarea {
  margin-top: 6px;
  padding: 8px;
  min-height: 4rem;
}

.knapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}
